import {ReactNode, useEffect, useState} from "react";
import Stack from "@mui/material/Stack";
import {accountList, getFavoriteRecipeList, recipeList} from "@/app/actions";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import {Recipe} from "@/app/models/Recipe";

type Props = {
    accounts: accountList;
    onSelect: (recipe: Recipe) => any;
}


export default function RecipeList({accounts, onSelect}: Props): ReactNode {
    const [recipes, setRecipes] = useState<recipeList>([]);

    useEffect(() => {
        getFavoriteRecipeList(accounts).then((r) => setRecipes(r))
    }, [accounts])

    return (<>
        <Stack sx={{height: '80vh'}}>
            <List>
                {recipes.map(recipe => (<ListItem key={recipe.id}>
                    <ListItemButton onClick={() => onSelect(recipe)}>
                        <ListItemText primary={recipe.title} />
                    </ListItemButton>
                </ListItem>))}
            </List>
        </Stack>
    </>);
}
