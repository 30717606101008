import {ReactNodeLike} from "prop-types";

import Link from "next/link";
import {Box, Grid, IconButton, Typography} from "@mui/material";

import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import {DishDto, markAsFavorite} from "@/app/actions";
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import {ReactNode, useState} from "react";

type Props = {
    header?: ReactNodeLike;
    dish: DishDto;
    accountId: string;
    userId: string;
};

export default function DayOfWeekShow({
                                          dish,
                                          accountId,
                                          userId
                                      }: Props): ReactNode {

    const [favorited, setFavorited] = useState<boolean>(!!dish.isFavorite);

    const titleOrUrl = (title: string | undefined, url: string | null | undefined) => {
        if (title && title.length > 0) {
            return title;
        }

        return url ?? '';
    }
    const mark = () => {
        markAsFavorite(dish, accountId, userId).then((r) => {
            setFavorited(true);
            console.info("recipe marked as favorite", r);
        })
    }

    return (<Grid container spacing={2}>
        <Grid item xs={2} sm={1}>
            {!favorited && dish.type !== 'fixed' &&
                <IconButton onClick={() => mark()}>
                    <BookmarkAddIcon></BookmarkAddIcon>
                </IconButton>
            }

            {favorited &&
                <Box title="Dit is een favoriet recept">
                    <BookmarkBorderIcon></BookmarkBorderIcon>
                </Box>
            }
        </Grid>

        <Grid item alignSelf={"center"} xs>
            {dish.recipe_url && dish.recipe_url.length !== 0 &&
                <Link href={dish.recipe_url ?? ''}
                      target="_blank">{titleOrUrl(dish.title, dish.recipe_url)}</Link>}
            {!(dish.recipe_url && dish.recipe_url.length !== 0) &&
                <Typography>{dish.title ?? ''}</Typography>}
            {dish.ingredientSummary?.length ? <>
                <Typography variant={'caption'}>{dish.ingredientSummary ?? ''}</Typography>
            </> : <></>}
        </Grid>
    </Grid>)
}
