'use client';
import {Box, Button} from "@mui/material";
import {useRouter, useSearchParams} from "next/navigation";
import {ReactNode} from "react";

export default function BottomNavigation(): ReactNode {
    const router = useRouter()
    const searchParams = useSearchParams()
    const days = parseInt(searchParams.get('days') ?? '0', 10);

    return (
        <Box>
            <Button variant="text" onClick={() => router.push(`?days=${days - 7}`)}>week eerder</Button> |
            <Button variant="text" onClick={() => router.push(`?days=${days - 1}`)}>eerder</Button>
            {days != 0 && <>
               | <Button variant="text" onClick={() => router.push(`?days=0`)}>vandaag</Button> |
            </>}
            {days < 0 && <>
                <Button variant="text" onClick={() => router.push(`?days=${days + 1}`)}>later</Button>
            </>}
            {days <= -7 && <>
                <Button variant="text" onClick={() => router.push(`?days=${days + 7}`)}>week later</Button>
            </>}
        </Box>
    )
}
